import {ApiBase} from '@jetCommon/api/base.js';

export default class JetAsyncTaskApi extends ApiBase {
    static resourceName = 'jet_async_tasks';

    get(taskId) {
        return this.apiGet(`${taskId}/`);
    }

    lastTaskForPerson(params) {
        return this.apiGet('last_task_for_person/', {params});
    }
}
